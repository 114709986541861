import * as React from "react"
import { Link } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
// import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import backgroundimg from '../../../static/fm7nz-bg.jpg'
import blockimg from '../../../static/block.svg'

const BannerModule = ({ children, title, subTitle, price, enquire }) => {
  // function scrollToArea() {
  //   navigate("topContent")
  // }

  return (
    <>
      <BannerModuleStyles>
        {children ? (
          children
        ) : (
          <div className="banner__image">
            <img src={backgroundimg} alt="Banner" />
          </div>
        )}
        
        <div className="container">
          <div className="banner__content">

          {children ? (
            <div></div>
            ):(<div>
              <img src={blockimg} alt="block info" className="block-info"/>
            </div>)}

            
            
            {title && (
              <h1>
                {title}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h1>
            )}
            {subTitle && <h2>{subTitle}</h2>}
            {price && (
              <h2 className="price">
                £{price}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h2>
            )}
            <div className="banner__btns">             
              <Button text="Learn More about FM7" 
                  as={Link}
                  to="/about" />
            </div>
          </div>
        </div>
        <div className="gradient"></div>
      </BannerModuleStyles>
      <span id="topContent"></span>
    </>
  )
}

export default BannerModule
